import React, { useState } from 'react'
import Link from 'gatsby-link'
import {
  makeStyles,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Divider,
  Typography,
  Chip,
  Hidden,
  AppBar,
  Avatar,
  CardContent
} from '@material-ui/core'
import { Index } from 'elasticlunr'
import { fade } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import InputBase from '@material-ui/core/InputBase';
import { navigate } from 'gatsby'
import kebabCase from "lodash/kebabCase"
import debounce from "lodash/debounce"
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    margin: theme.spacing(4),
    backgroundColor: '#fff',
  },
  list: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    textDecoration: 'none'
  },
  titleLine: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  listTitle: {
    marginRight: theme.spacing(2),
  },
  category: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchBackground: {
    background: '#FFFDE7',
  },
  verticalCenter: {
    padding: '0px 0',
  }
}))

let searchValue = ''

const Search = ({ data, searchIndex }) => {
  const [query, setQuery] = useState(``)
  const [results, setResults] = useState([])
  let index

  const classes = useStyles()

  let patterns = []

  const listItems = items => items
    .sort(function(a, b){return Date.parse(b.isoDate)-Date.parse(a.isoDate)})
    .map(({ guid, title, categories, author, location, country, companyLogo, isoDate}, pIndex) => {
    return (
      <>
        <ListItem key={`search-${guid}`} alignItems="flex-start" button onClick={
          event => {
            navigate(`/jobs/${guid}`)
          }
        }>
          <ListItemAvatar>
            {/* fetching these images blocks the rendering, we need to bring back gatsby-images  */}
            <Avatar alt={author} variant="rounded" src={`/logo/${companyLogo}`} />
          </ListItemAvatar>
          <ListItemText
            primary={title.split(" at ")[0]}
            secondary={
              <React.Fragment>
                <Typography
                  component="p"
                  variant="body2"
                  color="textSecondary"
                >
                {title.split(" at ")[1]}
                </Typography>
                  {categories != undefined && categories.map((tag, key) => (
                    <Chip key={`search-${guid}-${tag}`} label={`#${tag}`} size='small' clickable={true} onClick={
                      event => {
                        event.stopPropagation()
                        navigate(`/tags/${kebabCase(tag)}`)
                      }
                    }/>
                  ))}
              </React.Fragment>
            }
          />
        </ListItem>
        {pIndex < items.length - 1 && <Divider variant="inset" component="li" />}
        </>
  )})

  const getOrCreateIndex = () => {
    return index ? index : Index.load(searchIndex)
  }

  const search = query => index.search(`${query}`, { expand: true })
  const mapResults = results => results.map(({ ref }) => index.documentStore.getDoc(ref))

  const [debouncedCallApi] = useState(() => debounce(doSearch, 1000));

  function handleSearch(evt) {
    const searchText = evt.target.value
    setQuery(searchText)
    //we need this to pass value inside the debounce... probably some context issues
    searchValue = searchText
    debouncedCallApi()
  }

  function doSearch() {
    index = getOrCreateIndex()
    const mappedResults = mapResults(search(searchValue))
    setResults(mappedResults)
  }

  return (
  <>
    <AppBar position="static">
      <Toolbar>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            id="search"
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            value={query}
            onChange={handleSearch}
          />
        </div>
      </Toolbar>
    </AppBar>
    {
      query ?
      <div className={classes.searchBackground}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            Search results from the last 10 days.
          </Typography>
          <Typography color="textSecondary" className={classes.verticalCenter} gutterBottom>
            If you would like to see more, please select a tag or category below. <WarningIcon fontSize="small" />
          </Typography>
        </CardContent>
        <List className={clsx(classes.list, classes.searchBackground)}>
          { listItems(results) }
        </List>
      </div>
      : <></>
    }
  </>
  )
}

export default Search