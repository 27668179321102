import React from 'react'

import { graphql, Link, navigate} from 'gatsby'
import Layout from '../components/Layout'
import kebabCase from "lodash/kebabCase"
import { Chip, ListItemSecondaryAction } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

import SearchWrapper from '../components/search-wrapper'

import SEO from '../components/seo'

import WorkIcon from '@material-ui/icons/NewReleases';
import CodeIcon from '@material-ui/icons/Code';
import PublicIcon from '@material-ui/icons/Public';
import BusinessIcon from '@material-ui/icons/Business';
import FlightLandIcon from "@material-ui/icons/FlightLand"
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff"
import StartsIcon from "@material-ui/icons/Stars"

import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import NotificationInLine from '../components/notification-inline';

import AdSense from 'react-adsense';
import AdContainerWhite from '../components/ad-container-white';

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: '0.5s',
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  inline: {
    display: 'inline',
  },

  listitem: {
    maxWidth: '800px',
    marginLeft: '8px',
    marginRight: '8px',
    backgroundColor: theme.palette.background.paper,
  },

  postedDiff: {
    fontSize: "1em",
  },

  noLink: {
    textDecoration: "none",
  },
  
}));


const IndexPage = ({ data }) => {
  const classes = useStyles();

  const handleClick = (guid) => {
    navigate(`/jobs/${guid}`)
  };
  
  const mapMarkdown = ({allJobs}) => allJobs.edges.map(({node}) => ({
    guid: node.guid,
    title: node.title,
    categories: node.categories,
    author: node.author.name,
    location: node.location,
    country: node.country,
    companyLogo: node.companyLogo,
  }))

  //pre-sort tags:
  data.allCategories.group.sort(function(tagA, tagB){return tagB.totalCount - tagA.totalCount}); 
  data.allLocations.group.sort(function(tagA, tagB){return tagB.totalCount - tagA.totalCount}); 
  data.allCompanies.group.sort(function(tagA, tagB){return tagB.totalCount - tagA.totalCount}); 

  const filteredPromos = data.promoJobs.edges.filter(edge => Math.round((Date.now() - Date.parse(edge.node.promoMainIsoDate))/(24*3600*1000)) <= 0 )

  return (
  <Layout>
      <SEO title="Jobs with Visa sponsorship"/>
      <SearchWrapper data={ mapMarkdown(data) } />
      { filteredPromos.length > 0 && <>
      <><br/></>
      <Card style={{background: '#e8ebf7', }} raised>
      <CardContent style={{ "display": "flex", "flex-direction": "row", "flex-wrap": "nowrap", "justify-content": "space-between"}}>
      <Chip
        icon={<StartsIcon />}
        label="Promoted jobs"
        color="primary"
        variant="outlined"
      />
      <StartsIcon color="primary" opacity="0.3" />
      </CardContent>
      <List className={classes.listitem} style={{background: '#e8ebf7', }} >
      {filteredPromos.map((edge, key) => {

        const postedDays = Math.round((Date.now() - Date.parse(edge.node.isoDate))/(24*3600*1000));
        const postedText = postedDays === 0 ? "today" : `${postedDays}d`

        return (
          <>
          <ListItem key={`top-${edge.node.guid}`} alignItems="flex-start" button onClick={
            event => {
              navigate(`/jobs/${edge.node.guid}`)
            }
          }>
            <ListItemAvatar>
              {/* fetching these images blocks the rendering, we need to bring back gatsby-images  */}
              <Avatar alt={edge.node.author.name} variant="rounded" src={`/logo/${edge.node.companyLogo}`} />
            </ListItemAvatar>
            <ListItemText
              primary={edge.node.title.split(" at ")[0]}
              secondary={
                <React.Fragment>
                  <Typography
                    component="p"
                    variant="body2"
                    color="textSecondary"
                  >
                  {edge.node.title.split(" at ")[1]}
                  </Typography>
                    {edge.node.categories != undefined && edge.node.categories.map((tag, key) => (
                      <Chip key={`top-${edge.node.guid}-${tag}`} label={`#${tag}`} size='small' clickable={true} onClick={
                        event => {
                          event.stopPropagation()
                          navigate(`/tags/${kebabCase(tag)}`)
                        }
                      }/>
                    ))}
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <Typography color="textSecondary" className={classes.postedDiff}>
                {`${postedText}`}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
          { filteredPromos.length > 1 && <Divider variant="inset" component="li" /> }
          </>
        ) 
      })}
      </List>
      </Card>
      <><br/></>
      </> }
      <Card>
      <CardContent>
      <Chip
        icon={<WorkIcon />}
        label="Latest jobs"
        color="primary"
        variant="outlined"
      />
      </CardContent>
      <List className={classes.listitem}>
      {data.allJobs.edges.slice(0, 20).map((edge, key) => {

        const postedDays = Math.round((Date.now() - Date.parse(edge.node.isoDate))/(24*3600*1000));
        const postedText = postedDays === 0 ? "today" : `${postedDays}d`
        let validPromo = edge.node.promoMainIsoDate != undefined && edge.node.promoMainIsoDate != ""
        if (validPromo) {
          validPromo = Math.round((Date.now() - Date.parse(edge.node.promoMainIsoDate))/(24*3600*1000)) <= 0
        }

        return (
          <>
          <ListItem key={`top-${edge.node.guid}`} alignItems="flex-start" style={validPromo ? {background: '#e8ebf7'} : {}} button onClick={
            event => {
              navigate(`/jobs/${edge.node.guid}`)
            }
          }>
            <ListItemAvatar>
              {/* fetching these images blocks the rendering, we need to bring back gatsby-images  */}
              <Avatar alt={edge.node.author.name} variant="rounded" src={`/logo/${edge.node.companyLogo}`} />
            </ListItemAvatar>
            <ListItemText
              primary={edge.node.title.split(" at ")[0]}
              secondary={
                <React.Fragment>
                  <Typography
                    component="p"
                    variant="body2"
                    color="textSecondary"
                  >
                  {edge.node.title.split(" at ")[1]}
                  </Typography>
                    {edge.node.categories != undefined && edge.node.categories.map((tag, key) => (
                      <Chip key={`top-${edge.node.guid}-${tag}`} label={`#${tag}`} size='small' clickable={true} onClick={
                        event => {
                          event.stopPropagation()
                          navigate(`/tags/${kebabCase(tag)}`)
                        }
                      }/>
                    ))}
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <Typography color="textSecondary" className={classes.postedDiff}>
                {`${postedText}`}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="inset" component="li" />
          </>
        ) 
      })}
      </List>
      </Card>
      <><br/></>
      <NotificationInLine />
      <><br/></>
      <Card>
        <CardContent>
          <Chip
            icon={<FlightTakeoffIcon />}
            label="Relocation and visa support agencies"
            color="primary"
            variant="outlined"
          />
        </CardContent>
        <CardContent>
          Have you found your dream job already? Ready to relocate but don't know where to start?
          <br /><br />
          Then take a look at our curated list of relocation and visa support agencies that can help you kick-off your new journey!
          <br /><br />
          <a href={`/relocations`} className={classes.noLink}>
            <div style={{textAlign: 'center', }}>
            <Button variant="contained" color="secondary"  variant="outlined">
              <FlightTakeoffIcon />
              Find an agent
            </Button>
            </div>
          </a>
        </CardContent>
      </Card>
      <><br/></>
      <AdContainerWhite title='An ad to keep our services up and running. Sorry.'>
        <AdSense.Google
            client='ca-pub-9152097609554171'
            slot='5290184966'
            style={{ display: 'block' }}
            format='auto'
            responsive='true'
        />
      </AdContainerWhite>
      <><br/></>
      <Card>
        <CardContent>
          <Chip
            icon={<CodeIcon />}
            label="Technologies you can work with"
            color="primary"
            variant="outlined"
          />
        </CardContent>
        <CardContent>
          {data.allCategories.group.filter((i, index) => (index < 20)).map(tag => (
            <a key={tag.fieldValue} href={`/tags/${kebabCase(tag.fieldValue)}`}>
              <Chip key={`chip-${tag.fieldValue}`} label={`#${tag.fieldValue}`} size='small' clickable={true} />
            </a>
          ))}
        </CardContent>
        <Accordion onChange={e => {
          // To stop the page reloading
          e.preventDefault()
          // Lets track that custom click
          trackCustomEvent({
            category: "Expand",
            action: "Click",
            label: "Expand tags",
          })
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-category-content"
            id="panel-category-header"
          >
            <Typography className={classes.heading} color="textSecondary">There is more...</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CardContent>
              {data.allCategories.group.filter((i, index) => (index >= 20)).map(tag => (
                <a key={tag.fieldValue} href={`/tags/${kebabCase(tag.fieldValue)}`}>
                  <Chip key={`chip-${tag.fieldValue}`} label={`#${tag.fieldValue}`} size='small' clickable={true} />
                </a>
              ))}
            </CardContent>
          </AccordionDetails>
        </Accordion>
      </Card>
      <><br/></>
      <Card>
        <CardContent>
          <Chip
            icon={<PublicIcon />}
            label="Countries where you can move"
            color="primary"
            variant="outlined"
          />
        </CardContent>
        <CardContent>
          {data.allLocations.group.filter((i, index) => (index < 20)).map(tag => (
            <a key={tag.fieldValue} href={`/countries/${kebabCase(tag.fieldValue)}`}>
              <Chip key={`chip-${tag.fieldValue}`} label={`#${tag.fieldValue}`} size='small' clickable={true} />
            </a>
          ))}
        </CardContent>
        <Accordion onChange={e => {
          // To stop the page reloading
          e.preventDefault()
          // Lets track that custom click
          trackCustomEvent({
            category: "Expand",
            action: "Click",
            label: "Expand locations",
          })
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-location-content"
            id="panel-location-header"
          >
            <Typography className={classes.heading}>There is more...</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CardContent>
              {data.allLocations.group.filter((i, index) => (index >= 20)).map(tag => (
                <a key={tag.fieldValue} href={`/countries/${kebabCase(tag.fieldValue)}`}>
                  <Chip key={`chip-${tag.fieldValue}`} label={`#${tag.fieldValue}`} size='small' clickable={true} />
                </a>
              ))}
            </CardContent>
          </AccordionDetails>
        </Accordion>
      </Card>
      <><br/></>
      <Card>
        <CardContent>
          <Chip
            icon={<BusinessIcon />}
            label="Companies who you can work for"
            color="primary"
            variant="outlined"
          />
        </CardContent>
        <CardContent>
          {data.allCompanies.group.filter((i, index) => (index < 20)).map(tag => (
            <a key={tag.fieldValue} href={`/companies/${kebabCase(tag.fieldValue)}`}>
              <Chip key={`chip-${tag.fieldValue}`} label={`#${tag.fieldValue}`} size='small' clickable={true} />
            </a>
          ))}
        </CardContent>
        <Accordion onChange={e => {
          // To stop the page reloading
          e.preventDefault()
          // Lets track that custom click
          trackCustomEvent({
            category: "Expand",
            action: "Click",
            label: "Expand companies",
          })
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-location-content"
            id="panel-location-header"
          >
            <Typography className={classes.heading}>There is more...</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CardContent>
              {data.allCompanies.group.filter((i, index) => (index >= 20)).map(tag => (
                <a key={tag.fieldValue} href={`/companies/${kebabCase(tag.fieldValue)}`}>
                  <Chip key={`chip-${tag.fieldValue}`} label={`#${tag.fieldValue}`} size='small' clickable={true} />
                </a>
              ))}
            </CardContent>
          </AccordionDetails>
        </Accordion>
      </Card>
  </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allJobs: allJobsJson(
        sort: { order: DESC, fields: [isoDate] }
        limit: 1000
      ) {
      edges {
        node {
          guid
          title
          categories
          companyLogo
          author {name: a10_name}
          isoDate
          promoMainIsoDate
        }
      }
    }

    promoJobs: allJobsJson(
        sort: { order: DESC, fields: isoDate }, filter: { promoMainIsoDate: { gt: "0" } }
        limit: 1000
      ) {
      edges {
        node {
          guid
          title
          categories
          companyLogo
          author {name: a10_name}
          isoDate
          promoMainIsoDate
        }
      }
    }

    allCategories: allJobsJson(limit: 2000) {
      group(field: categories) {
        fieldValue
        totalCount
      }
    }
    allLocations: allJobsJson(limit: 2000) {
      group(field: country) {
        fieldValue
        totalCount
      }
    }
    allCompanies: allJobsJson(limit: 2000) {
      group(field: author___a10_name) {
        fieldValue
        totalCount
      }
    }
  }
`
